import React from "react"
import { Link } from "gatsby"
import ReactTextRotator from "react-text-rotator"

import { FaPhoneSquare } from "react-icons/fa"

const content = [
  {
    text: "Immer meisterhaft bedacht",
    animation: "fade",
  },
  {
    text: "Auf geht's!",
    animation: "fade",
  },
  {
    text: "Wo wir sind ist oben.",
    animation: "fade",
  },
]

export default props => (
  <div>
    <div className="arrow-up red" />
    <div className="arrow-down red" />
    <div
      className={
        props.isFoot
          ? "contentbox telephone foot text-center"
          : "contentbox telephone text-center"
      }
    >
      <Link to={"/kontakt"} className="white-link">
        <h3>
          <ReactTextRotator
            content={content}
            transitionTime={250}
            time={3000}
            startDelay={0}
          />
        </h3>
        <hr />
        <h3>{props.Text ? props.Text : "Wir beraten Sie gerne"}</h3>
        <p />
        <h2>
          <FaPhoneSquare
            className="mirror"
            style={{
              position: "relative",
              top: "-3px",
            }}
          />{" "}
          02133 / 63294
        </h2>
      </Link>
    </div>
  </div>
)
